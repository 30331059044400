@import url(https://fonts.googleapis.com/css2?family=Eagle+Lake&family=Zhi+Mang+Xing&family=Krona+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes fadebg {
  from {background-color: #A58476;}
  to {background-color: rgb(70 37 37 / 50%) ; text-shadow: 3px 3px rgba(0,0,0,0.8);}
}

@keyframes fadebg {
  from {background-color: #A58476;}
  to {background-color: rgb(70 37 37 / 50%) ; text-shadow: 3px 3px rgba(0,0,0,0.8);}
}
body{
  overflow-x: hidden;
}

header{
  height:100vh;
  background:#A58476;
  -webkit-animation-name: fadebg;
          animation-name: fadebg;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.background{
  background:url(/static/media/compressbackground.60099caf.jpg);
  background-position: center;
  background-size: cover;
}
.hou,.wei{
  font-size:96px;
  font-family: 'Eagle Lake', cursive;
}

.houChinese,.weiChinese{
  font-size:288px;
  font-family: 'Zhi Mang Xing', cursive;
}

.hou, .houChinese{
  color:#EEEEEE;
}

.wei, .weiChinese{
  color:#ffd70f;
}

.hou{
  position:absolute;
  top:25%;
  left:25%;
}

.houChinese{
  position:absolute;
  top:8%;
  left:calc(25% + 250px);
}

.wei{
  position:absolute;
  top:calc(25% + 200px);
  left:30%;
}

.weiChinese{
  position:absolute;
  top:calc(25% + 150px);
  left:calc(30% + 230px);
}

.menu{
  background:#343232;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding:100px;
}

.menutab{
  color:#FFD749;
  font-size: 25px;
  min-width:40%;
  margin:10px;
  flex:1 1;
  font-family: 'Krona One', sans-serif;
  cursor:pointer;
}

.menutab:hover{
  color:#f7c71f;
}

.menuitemwrapper{
  color: wheat;
  font-size: 18px;
  padding-top:20px;
}

.menuitem{
  font-family: sans-serif;
}
.location{
  overflow:hidden;
  background:#171717;
  min-height:100vh;
  box-sizing: border-box;
  padding:40px;
  color:white;
  font-size:24px;
}

.location > .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.location > h2{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
}
.location > .wrapper > .hour{
  flex:1 1;
  min-width: 400px;
}

@media only screen and (max-width:750px){
  .menutab{
    min-width:100%;
  }
  .location{
    padding:30px;
  }
  .hou,.wei{
    font-size:80px;
  }
  .menu{
    padding:50px;
  }
  .houChinese,.weiChinese{
    font-size:150px;
    left:25%;
  }
  .wei{
    top:calc(35% + 190px);
  }
}
footer{
  text-align: center;
  color:white;
  padding:100px;
  background:#252934;
}
footer a{
  color:white !important;
}
